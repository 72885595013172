export const itemPerPageOptions = [
  {
    value: 10,
    name: '10',
  },
  {
    value: 25,
    name: '25',
  },
  {
    value: 50,
    name: '50',
  },
];
