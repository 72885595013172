<template>
  <sm-modal
    :show="show"
    :modal-title="modalTitle"
    class="form-modal"
    @close="onClose"
  >
    <template #body>
      <sm-editable-form
        v-model="values"
        :fields="fields"
        @select="onSelect"
        @changeFile="onChangeFile"
      >
        <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="field">
          <slot :name="slot" v-bind="field"></slot>
        </template>
      </sm-editable-form>
      <div class="form-modal__buttons">
        <sm-button
          v-if="showSaveButton"
          class="form-modal__button"
          :disabled="disabledSaveButton"
          :isLoading="isLoadingSaveButton"
          @click="onSave"
          >{{ titleSaveButton }}</sm-button
        >
        <sm-button
          v-if="showCancelButton"
          neutrall
          outline
          class="form-modal__button"
          @click="onCancel"
          >
            Отменить
          </sm-button
        >
      </div>
    </template>
  </sm-modal>
</template>

<script>
import SmModal from '@/components/common/modals/SmModal.vue';
import SmEditableForm from '@/components/common/forms/SmEditableForm.vue';
import SmButton from '@/components/common/buttons/SmButton.vue';

export default {
  name: 'SmFormModal',

  components: {
    SmModal,
    SmEditableForm,
    SmButton,
  },

  model: {
    prop: 'values',
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    modalTitle: {
      type: String,
      default: '',
    },

    values: {
      type: Object,
      required: true,
    },

    fields: {
      type: Array,
      required: true,
    },

    disabledSaveButton: {
      type: Boolean,
      default: false,
    },

    isLoadingSaveButton: {
      type: Boolean,
      default: false,
    },

    titleSaveButton: {
      type: String,
      default: 'Сохранить',
    },

    showSaveButton: {
      type: Boolean,
      default: true,
    },

    showCancelButton: {
      type: Boolean,
      default: true,
    }
  },

  methods: {
    onClose() {
      this.$emit('close');
    },

    onSelect(value) {
      this.$emit('select', value);
    },

    onChangeFile(fileData) {
      this.$emit('changeFile', fileData);
    },

    onSave() {
      this.$emit('save');
    },

    onCancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss">
.form-modal .form-generator__cell {
  max-width: 100%;
}

.form-modal .form-generator {
  flex-direction: column;
}

.form-modal__buttons {
  display: flex;
}

.form-modal__button {
  width: 180px;
  &:first-of-type {
    margin-right: 15px;
  }
}
</style>
