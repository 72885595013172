<template>
  <ul class="pagination">
    <sm-pagination-item @click.native="prevPage" :disabled="currentPage <= 1"
      ><sm-icon class="pagination__icon" name="chevron-left"
    /></sm-pagination-item>
    <sm-pagination-item v-if="isActiveFirstPage" @click.native="changePage(1)"
      >1</sm-pagination-item
    >
    <sm-pagination-item v-if="isActiveFirstPage && pages[0] - 1 !== 1" disabled
      >...</sm-pagination-item
    >
    <sm-pagination-item
      v-for="page in pages"
      :key="page"
      @click.native="changePage(page)"
      :active="page === currentPage"
      >{{ page }}</sm-pagination-item
    >
    <sm-pagination-item
      v-if="isActiveLastPage && pages[pages.length - 1] + 1 !== totalPages"
      disabled
      >...</sm-pagination-item
    >
    <sm-pagination-item
      v-if="isActiveLastPage"
      @click.native="changePage(totalPages)"
      >{{ totalPages }}</sm-pagination-item
    >
    <sm-pagination-item
      @click.native="nextPage"
      :disabled="currentPage >= totalPages"
    >
      <sm-icon class="pagination__icon" name="chevron-right" />
    </sm-pagination-item>
  </ul>
</template>

<script>
import SmPaginationItem from '@/components/common/pagination/SmPaginationItem.vue';
import SmIcon from '@/components/common/SmIcon.vue';

export default {
  name: 'SmPagination',

  components: {
    SmPaginationItem,
    SmIcon,
  },

  props: {
    totalItems: {
      type: Number,
    },

    itemsPerPage: {
      type: Number,
    },

    activePage: {
      type: Number,
      default: 1,
    },
  },

  // data() {
  //   return {
  //     activePage: 1,
  //   };
  // },

  watch: {
    totalPages: {
      handler() {
        // this.activePage = 1;
        this.currentPage = 1;
      },
    },
  },

  computed: {
    currentPage: {
      get() {
        return this.activePage;
      },
      set(value) {
        this.$emit('changePage', value);
      },
    },

    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    rangeStart() {
      let start = this.currentPage - 1;
      return start > 0 ? start : 1;
    },
    rangeEnd() {
      let end = this.currentPage + 1;
      return end < this.totalPages ? end : this.totalPages;
    },
    pages() {
      let pages = [];
      for (let i = this.rangeStart; i <= this.rangeEnd; i++) {
        pages.push(i);
      }
      return pages;
    },
    isActiveFirstPage() {
      return this.rangeStart !== 1;
    },
    isActiveLastPage() {
      return this.rangeEnd < this.totalPages;
    },
  },

  methods: {
    changePage(page) {
      this.currentPage = page;
      // this.$emit('changePage', page);
    },
    nextPage() {
      this.currentPage += 1;
      // this.$emit('changePage', this.currentPage);
    },
    prevPage() {
      this.currentPage -= 1;
      // this.$emit('changePage', this.currentPage);
    },
  },
};
</script>

<style>
.pagination {
  display: flex;
  list-style: none;
}

.pagination__item--disabled {
  pointer-events: none;
}

.pagination__link {
  display: inline-block;
  padding: 8px 12px;

  font-size: 16px;
  /* line-height: 1; */
  color: var(--black);
  text-decoration: none;

  border: none;
  background: none;

  background-color: transparent;
}

.pagination__item--disabled .pagination__link {
  color: var(--gray);
}

.pagination__item--active .pagination__link {
  border-radius: 3px;
  background-color: var(--blue);
  color: var(--white);
  -webkit-box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%),
    0 2px 10px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}
</style>
