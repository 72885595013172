<template>
  <li
    class="pagination__item"
    :class="{
      'pagination__item--active': active,
      'pagination__item--disabled': disabled,
    }"
  >
    <router-link to="" class="pagination__link"><slot></slot></router-link>
  </li>
</template>

<script>
export default {
  name: 'SmPaginationItem',

  props: {
    active: {
      type: Boolean,
    },

    disabled: {
      type: Boolean,
    },
  },
};
</script>
