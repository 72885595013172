<template>
  <ul class="breadcrumbs">
    <li v-for="(item, index) in items" :key="index" class="breadcrumbs__item">
      <router-link
        :to="item.route || ''"
        exact
        class="breadcrumbs__link"
        :class="{ 'breadcrumbs__link--disabled': !item.route }"
        >{{ item.text }}</router-link
      >
    </li>
  </ul>
</template>

<script>
export default {
  name: 'SmBreadcrumbs',

  props: {
    items: {
      type: Array,
    },
  },
};
</script>

<style>
.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  font-size: 14px;
  color: var(--gray);
}

.breadcrumbs__item {
  position: relative;

  margin-right: 5px;
}

.breadcrumbs__item:last-child {
  margin-right: 0;
}

.breadcrumbs__item:after {
  content: '';

  position: absolute;
  top: 4px;
  right: -3px;

  display: block;
  width: 1px;
  height: 12px;

  background-color: var(--gray);

  transform: skew(-12deg);
}

.breadcrumbs__item:last-child:after {
  display: none;
}

.breadcrumbs__link {
  color: var(--gray);

  transition: color 0.35s;
}

.breadcrumbs__link:hover {
  color: var(--blue);
}

.breadcrumbs__link--disabled {
  color: var(--black);

  pointer-events: none;
  cursor: default;
}

.breadcrumbs__link.router-link-exact-active {
  color: var(--black);
}
</style>
