<template>
  <ul class="tabs">
    <li v-for="(tab, index) in tabs" :key="index" class="tabs__item">
      <router-link
        class="tabs__link"
        :class="{ tabs__link_active: activeIndex === index }"
        :to="tab.route || ''"
        @click.native="choseTab(index)"
      >
        <sm-icon v-if="tab.icon" :name="tab.icon" />{{ tab.text }}
      </router-link>
    </li>
  </ul>
</template>

<script>
import SmIcon from '@/components/common/SmIcon.vue';
export default {
  name: 'SmTabs',

  components: {
    SmIcon,
  },

  props: {
    tabs: {
      type: Array,
    },

    activeTab: {
      type: Number,
      default: 0,
    },

    underline: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      activeIndex: this.activeTab,
      currentComponent: '',
    };
  },

  methods: {
    choseTab(index) {
      this.activeIndex = index;
      this.$emit('activeTab', this.activeIndex);
    },
  },
};
</script>

<style lang="scss">
.tabs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 38px 10px;
  margin-bottom: 30px;

  font-size: 14px;

  background-color: rgba(var(--rgb-blue), 0.04);
}

.tabs__item {
  margin: 12px;

  white-space: nowrap;

  &:last-of-type {
    margin-right: 0;
  }
}

.tabs__link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  padding: 0 1.25em;

  font-weight: 400;
  line-height: 1;
  color: var(--black);

  border: 0.5px solid rgba(0, 160, 227, 0.4);
  border-radius: 10px;

  transition: color 0.3s;

  &:hover {
    color: var(--blue);
  }
}

.tabs__link_active {
  font-weight: 600;
  color: var(--white);

  border: 0.5px solid rgba(var(--rgb-blue), 0.4);
  background-color: var(--blue);

  pointer-events: none;
}
</style>
