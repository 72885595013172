<template>
  <div class="datatable__wrapper">
    <!-- TOP PANEL -->
    <caption class="datatable__top-panel">
      <h2 class="datatable__caption">
        {{ caption }}
      </h2>
      <slot name="top-panel"></slot>
    </caption>
    <!-- / TOP PANEL -->

    <!-- TABLE -->
    <div class="datatable__inner-wrapper">
      <table ref="datatable" class="datatable">
        <!-- COLGROUP -->
        <col v-if="multiselect" width="60px" span="1" />
        <slot name="colgroup"></slot>
        <!-- / COLGROUP -->

        <!-- TABLE HEADER -->
        <thead class="datatable__head">
          <tr class="datatable__row">
            <th
              v-if="multiselect"
              class="datatable__head-cell datatable__multiselect-cell"
            >
              <sm-checkbox
                :modelValue="isSelectedAllItems"
                @change="onSelectAllItems"
              />
            </th>
            <th
              v-for="(headCell, headIndex) in headers"
              :key="headIndex"
              class="datatable__head-cell"
              :class="{
                'datatable__head-cell--sorted': headCell.order === currentOrder,
                'datatable__head-cell--sorted-asc':
                  headCell.order === currentOrder &&
                  currentSortedType === 'asc',
              }"
              @click="onSortBy(headCell.order)"
            >
              <div class="datatable__head-cell-text">
                <div class="datatable__icon-wrapper">
                  <sm-icon
                    v-if="sort && headCell.order"
                    class="datatable__icon"
                    name="arrow-down"
                  />
                </div>
                {{ headCell.text }}
              </div>
            </th>
          </tr>
        </thead>
        <!-- / TABLE HEADER -->

        <!-- TABLE BODY -->
        <tbody class="datatable__body">
          <template v-for="(row, rowIndex) in items">
            <tr :key="rowIndex" class="datatable__row">
              <td
                v-if="multiselect"
                class="datatable__cell datatable__multiselect-cell"
              >
                <sm-checkbox
                  :modelValue="checkedItemsLocale"
                  :value="row"
                  @change="onSelectItem"
                />
              </td>
              <td
                v-for="(headCell, headIndex) in headers"
                :key="headIndex"
                class="datatable__cell"
                :class="[
                  {
                    'datatable__cell--right': setAlightText(
                      row[headCell.alias]
                    ),
                  },
                  `datatable__cell--${headCell.alias}`,
                ]"
              >
                <slot :name="headCell.alias" :row="row" :index="rowIndex">
                  {{ row[headCell.alias] }}
                </slot>
              </td>
            </tr>
            <slot :name="`row-bottom-${rowIndex}`"></slot>
          </template>

          <tr v-if="!items.length" class="datatable__empty-row">
            <td class="datatable__empty-cell" :colspan="headers.length">
              Результатов не найдено
            </td>
          </tr>
        </tbody>
        <!-- / TABLE BODY -->
      </table>
    </div>
    <!-- / TABLE -->

    <!-- BOTTOM PANEL -->
    <div v-if="items.length" class="datatable__bottom-panel">
      <!-- SELECT ITEM PER PAGE -->
      <div
        v-if="selectItemsPerPage"
        class="datatable__select-label"
        :class="{ 'pagination-table__bottom-panel--mod': showOnlyPagination }"
      >
        Отображать:
        <sm-select
          v-model="curentItemPerPage"
          :options="itemPerPageOptions"
          class="datatable__select"
        />
      </div>
      <!-- / SELECT ITEM PER PAGE -->

      <!-- PAGINATION -->
      <sm-pagination
        v-if="pagination"
        :activePage="activePage"
        :total-items="totalItems"
        :items-per-page="itemsPerPage"
        class="datatable__pagination"
        @changePage="onChangePage"
      />
      <!-- / PAGINATION -->
    </div>
    <!-- / BOTTOM PANEL -->
  </div>
</template>

<script>
import SmCheckbox from '@/components/common/forms/SmCheckbox.vue';
import SmIcon from '@/components/common/SmIcon.vue';
import SmSelect from '@/components/common/forms/SmSelect.vue';
import SmPagination from '@/components/common/pagination/SmPagination.vue';
import { itemPerPageOptions } from '@/constants/table.js';

export default {
  name: 'SmDatatable',

  components: {
    SmCheckbox,
    SmIcon,
    SmSelect,
    SmPagination,
  },

  props: {
    caption: {
      type: String,
      default: '',
    },

    headers: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    sort: {
      type: Boolean,
      default: false,
    },

    pagination: {
      type: Boolean,
      default: false,
    },

    selectItemsPerPage: {
      type: Boolean,
      default: false,
    },

    totalItems: {
      type: Number,
    },

    itemsPerPage: {
      type: Number,
    },

    multiselect: {
      type: Boolean,
      default: false,
    },

    checkedItems: {
      type: Array,
      default: () => [],
    },

    activePage: {
      type: Number,
      default: 1,
    },

    activeOrder: {
      type: String,
    },

    sortedType: {
      type: String,
      default: 'desc',
    },
  },

  data() {
    return {
      itemPerPageOptions,
      checkedItemsLocale: [],
    };
  },

  computed: {
    isSelectedAllItems() {
      return this.items.length === this.checkedItemsLocale.length;
    },

    showBottomPanel() {
      return this.pagination || this.selectItemsPerPage;
    },

    showOnlyPagination() {
      return this.pagination && !this.selectItemsPerPage;
    },

    curentItemPerPage: {
      get() {
        return this.itemsPerPage;
      },
      set(value) {
        this.$emit('change-items-per-page', value);
      },
    },

    currentOrder: {
      get() {
        return this.activeOrder;
      },
      set(value) {
        this.$emit('sort-by', { order: value, type: this.currentSortedType });
      },
    },

    currentSortedType: {
      get() {
        return this.sortedType;
      },
      set(value) {
        this.$emit('sort-by', { order: this.currentOrder, type: value });
      },
    },
  },

  created() {
    // watch once for default selected items
    this.unwatchCheckedItemsProp = this.$watch(
      'checkedItems',
      (newVal) => {
        if (newVal) {
          this.checkedItemsLocale = newVal;
          this.unwatchCheckedItemsProp = null;
        }
      },
      { deep: true, immediate: true }
    );
  },

  methods: {
    onSelectAllItems() {
      if (this.isSelectedAllItems) {
        this.checkedItemsLocale = [];
      } else {
        this.checkedItemsLocale = this.items;
      }
      this.$emit('check-items', this.checkedItemsLocale);
    },

    onSelectItem(checkedItems) {
      this.checkedItemsLocale = checkedItems;
      this.$emit('check-items', this.checkedItemsLocale);
    },

    onSortBy(order) {
      if (!order) return;
      if (order !== this.currentOrder) {
        this.currentSortedType = 'desc';
        this.currentOrder = order;
      } else
        this.currentSortedType =
          this.currentSortedType === 'asc' ? 'desc' : 'asc';
    },

    onChangePage(page) {
      this.$emit('change-page', page);
    },

    setAlightText(value) {
      return (
        Number(value).toString() !== 'NaN' && typeof Number(value) === 'number'
      );
    },
  },
};
</script>

<style lang="scss">
.datatable__inner-wrapper {
  width: 100%;
  margin-bottom: 15px;

  // overflow-x: auto;

  // scrollbar-color: #dddddd #fff;

  // &::-webkit-scrollbar {
  //   height: 6px;
  // }

  // &::-webkit-scrollbar-track {
  //   background-color: var(--white);
  // }

  // &::-webkit-scrollbar-thumb {
  //   -webkit-border-radius: 2px;

  //   background-color: #dddddd;
  //   border-radius: 6px;
  // }
}

.datatable__top-panel {
  display: flex;
  justify-content: space-between;

  padding-bottom: 12px;
}

.datatable__caption {
  font-size: 24px;
  font-weight: 600;
}

.datatable {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;

  font-size: 14px;
  line-height: 1;
  font-weight: 400;

  border-color: var(--blue);
}

.datatable__head {
  height: 50px;

  color: var(--white);
}

.datatable__row {
  cursor: default;
}

.datatable__head-cell {
  padding: 0 5px;

  font-weight: 500;

  background-color: var(--blue);

  cursor: pointer;

  &:first-child {
    border-left: 1px solid var(--blue);
  }

  &:last-child {
    border-right: 1px solid var(--blue);
  }
}

.datatable__multiselect-cell {
  text-align: center;
}

.datatable__cell--right {
  text-align: right;
}

.datatable__head-cell-text {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 10px;
  word-wrap: break-all;
  overflow-wrap: anywhere;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.datatable__icon-wrapper {
  display: flex;
  justify-content: center;
  margin-right: 5px;
}

.datatable__icon {
  width: 15px;
  height: 15px;

  visibility: hidden;
  opacity: 0;

  transition: visibility 0.35s linear, opacity 0.35s linear;
  transition: transform 0.5s;
}

.datatable__head-cell:hover .datatable__icon {
  visibility: visible;
  opacity: 1;
}

.datatable__head-cell--sorted .datatable__icon {
  visibility: visible;
  opacity: 1;
}

.datatable__head-cell--sorted-asc .datatable__icon {
  transform: rotate(180deg);
}

.datatable__cell {
  padding: 1.1em;

  word-wrap: break-all;
  overflow-wrap: anywhere;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;

  border: 1px solid var(--blue);
}

.datatable__empty-row {
  text-align: center;

  font-size: 20px;
  color: var(--gray);
}

.datatable__empty-cell {
  padding: 20px;
}

.datatable__bottom-panel {
  display: flex;
  justify-content: space-between;
}

.datatable__select-label {
  display: flex;
  align-items: center;
}

.datatable__select {
  width: 80px;
  margin-left: 10px;
}

.datatable__pagination {
  align-items: center;
}

.datatable__bottom-panel--mod {
  justify-content: flex-end;
}
</style>
