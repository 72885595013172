<template>
  <sm-page-loader v-if="isLoading" />

  <div v-else class="editable-item">
    <!-- NAV -->
    <sm-breadcrumbs class="editable-item__breadcrumbs" :items="breadcrumbs" />
    <!-- / NAV -->

    <!-- PAGE HEADER -->
    <h2 class="editable-item__header">
      <slot name="page-header">{{ pageHeader }}</slot>
    </h2>
    <!-- / PAGE HEADER -->

    <!-- DEPENDENT LIST -->
    <ul class="editable-item__dependent-list" v-if="dependentLinks">
      <li class="editable-item__dependent-item">Перейти к</li>
      <li
        v-for="link in dependentLinks"
        :key="link.text"
        class="editable-item__dependent-item"
      >
        <router-link :to="link.route" class="editable-item__dependent-link">
          {{ link.text }}
        </router-link>
      </li>
    </ul>
    <!-- / DEPENDENT LIST -->

    <!-- TOP PANEL -->
    <slot name="top-panel"></slot>
    <!-- / TOP PANEL -->

    <!-- TABS NAV -->
    <sm-tabs v-if="tabs.length > 1" :tabs="tabs" @activeTab="onSelectTab" />
    <!-- / TABS NAV -->

    <!-- TAB CONTENT -->
    <template v-for="(item, tabIndex) in fields">
      <div
        v-show="activeTab === tabIndex"
        :key="tabIndex"
        class="editable-item__tab-content"
      >
        <slot :name="'tab-top-panel-' + tabIndex"></slot>
        <slot :name="'tab-' + tabIndex">
          <sm-editable-form
            v-if="item.form"
            v-model="values"
            :fields="item.form"
            :disabledAllFields="!hasPermissions || disabledAllFields"
            @inputField="onInput"
            @changeFile="onChangeFile"
            @downloadFile="onDownloadFile"
            @removeFile="onRemoveFile"
          >
            <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="field">
              <slot :name="slot" v-bind="field"></slot>
            </template>
          </sm-editable-form>

          <template v-else-if="item.groups">
            <div
              v-for="(group, groupIndex) in item.groups"
              :key="groupIndex"
              :class="{ 'editable-item__form-group': group.groupTitle }"
            >
              <slot :name="'group-top-panel-' + group.groupId"></slot>

              <h3
                v-if="group.groupTitle"
                class="editable-item__form-group-title"
              >
                {{ group.groupTitle }}
              </h3>

              <slot :name="'group-body-' + group.groupId">
                <sm-editable-form
                  v-model="values"
                  :fields="group.form"
                  :disabledAllFields="!hasPermissions || disabledAllFields"
                  @inputField="onInput"
                  @changeFile="onChangeFile"
                  @downloadFile="onDownloadFile"
                  @removeFile="onRemoveFile"
                >
                  <template
                    v-for="(_, slot) in $scopedSlots"
                    v-slot:[slot]="field"
                  >
                    <slot :name="slot" v-bind="field"></slot>
                  </template>
                </sm-editable-form>
              </slot>
            </div>
          </template>

          <template v-else-if="item.table">
            <sm-datatable
              :key="'table_' + tabIndex"
              :caption="item.table.caption"
              :headers="
                hasPermissions
                  ? item.table.headers
                  : item.table.headers.filter(
                      (item) => item.alias !== 'actions'
                    )
              "
              :items="item.table.items"
            >
              <template #top-panel>
                <button
                  v-if="item.table.showCreateButton && hasPermissions"
                  class="editable-list__top-panel-button editable-list__top-panel-button--create"
                  @click="openModal(item.table.key)"
                >
                  <sm-icon name="plus" />
                  Добавить
                </button>
              </template>

              <template #colgroup>
                <col
                  width="auto"
                  :span="
                    item.table.showEditButton || item.table.showDeleteButton
                      ? item.table.headers.length - 1
                      : item.table.headers.length
                  "
                />
                <col
                  v-if="
                    (item.table.headers.showEditButton ||
                      item.table.headers.showDeleteButton) &&
                    hasPermissions
                  "
                  width="80px"
                />
              </template>

              <template
                v-for="col in item.table.headers"
                v-slot:[col.alias]="row"
              >
                <slot
                  :name="`table-${item.table.key}-${col.alias}`"
                  v-bind="row"
                >
                </slot>
              </template>

              <template v-slot:actions="{ index }">
                <div
                  v-if="
                    (item.table.showEditButton ||
                      item.table.showDeleteButton) &&
                    hasPermissions
                  "
                  class="editable-list__datatable-buttons"
                >
                  <button
                    v-if="item.table.showEditButton"
                    class="editable-list__datatable-button"
                    @click="openModal(item.table.key, index)"
                  >
                    <sm-icon name="pencil-alt" />
                  </button>

                  <button
                    v-if="item.table.showDeleteButton"
                    class="editable-list__datatable-button"
                    @click="openDeleteModal(item.table.key, index)"
                  >
                    <sm-icon name="trash" />
                  </button>
                </div>
              </template>
            </sm-datatable>

            <sm-form-modal
              v-if="item.table && item.table.modalFields"
              v-model="modalForm"
              :key="'modal_' + tabIndex"
              :fields="item.table.modalFields"
              :show="showModalForm"
              :modal-title="item.table.caption"
              @close="onCancelSaveModalForm"
              @save="onSaveModalForm(item.table.key)"
              @cancel="onCancelSaveModalForm"
            />
          </template>
        </slot>
        <slot :name="'tab-bottom-panel-' + tabIndex"></slot>
      </div>
    </template>
    <!-- / TAB CONTENT -->

    <div class="editable-item__form-button-group">
      <slot name="footer">
        <template v-if="hasPermissions && !disabledAllFields">
          <sm-button
            class="editable-item__form-button"
            :disabled="disabledSaveButton"
            :isLoading="isLoadingSaveButton"
            @click="onSave"
            >{{ saveButtonText }}</sm-button
          >
          <sm-button
            class="editable-item__form-button"
            outline
            neutrall
            @click="onCancel"
            >{{ cancelButtonText }}</sm-button
          >
        </template>
        <sm-button v-else class="editable-item__form-button" @click="onCancel"
          >Назад</sm-button
        >
      </slot>
    </div>
  </div>
</template>

<script>
// vuex
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('pendingFiles');
// components
import SmPageLoader from '@/components/common/SmPageLoader.vue';
import SmBreadcrumbs from '@/components/common/breadcrumbs/SmBreadcrumbs.vue';
import SmTabs from '@/components/common/SmTabs.vue';
import SmEditableForm from '@/components/common/forms/SmEditableForm.vue';
import SmDatatable from '@/components/common/SmDatatable.vue';
import SmIcon from '@/components/common/SmIcon.vue';
import SmFormModal from '@/components/common/modals/SmFormModal.vue';
import SmButton from '@/components/common/buttons/SmButton.vue';

export default {
  name: 'SmEditableItem',

  components: {
    SmPageLoader,
    SmBreadcrumbs,
    SmTabs,
    SmEditableForm,
    SmDatatable,
    SmIcon,
    SmFormModal,
    SmButton,
  },

  model: {
    prop: 'values',
  },

  props: {
    controllerName: {
      type: String,
      require: true,
    },

    pageHeader: {
      type: [String, Number],
    },

    breadcrumbs: {
      type: Array,
      required: true,
    },

    dependentLinks: {
      type: Array,
    },

    fields: {
      type: Array,
      required: true,
    },

    values: {
      type: Object,
      required: true,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    saveButtonText: {
      type: String,
      default: 'Сохранить',
    },

    cancelButtonText: {
      type: String,
      default: 'Отменить',
    },

    disabledSaveButton: {
      type: Boolean,
      default: false,
    },

    isLoadingSaveButton: {
      type: Boolean,
      default: false,
    },

    disabledAllFields: {
      type: Boolean,
      default: false,
    },

    validatePermission: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      activeTab: 0,
      initialValues: null,
      editItemIndex: null,
      showModalForm: false,
      modalForm: {},
    };
  },

  computed: {
    hasPermissions() {
      if (!this.validatePermission) {
        return true;
      }
      return (
        this.$ability.checkPermissions('Table', this.controllerName, 'Edit') ||
        this.$ability.checkPermissions(
          'Table',
          this.controllerName,
          'CreateMany'
        ) ||
        this.$ability.checkPermissions(
          'Table',
          this.controllerName,
          'CreateContract'
        )
      );
    },

    tabs() {
      return this.fields.map((item) => ({
        text: item.tab,
      }));
    },
  },

  methods: {
    ...mapActions(['addFile', 'removeFiles']),

    onSelectTab(index) {
      this.activeTab = index;
    },

    onInput(eventData) {
      this.$emit('inputField', eventData);
    },

    onChangeFile({ file, key }) {
      if (this.values[key]?.newFileId) {
        this.removeFiles({ filesToRemove: [this.values[key].newFileId] });
      }

      if (file instanceof FileList) {
        this.isUploadFile = true;
        const fd = new FormData();

        this.fileNameUpload = file[0].name;
        fd.append('file', file[0], file[0].name);

        this.addFile(fd).then((newFileId) => {
          this.values[key] = {
            fileName: file[0].name,
            newFileId,
            updateAction: 'new',
          };

          this.$notify({
            header: 'Файл успешно загружен',
            type: 'success',
            timer: 5000,
          });

          this.isUploadFile = false;
        });
      }
    },

    onDownloadFile(key) {
      this.$emit('downloadFile', key);
    },

    onRemoveFile({ key }) {
      if (this.values[key]?.newFileId) {
        this.removeFiles({ filesToRemove: [this.values[key].newFileId] });
      }
      this.values[key] = {
        fileName: '',
        newFileId: null,
        updateAction: 'remove',
      };
    },

    openModal(tableKey, index) {
      if (index !== null && index !== undefined) {
        this.editItemIndex = index;
        this.modalForm = { ...this.values[tableKey][index] };
      } else {
        let currentTable = this.fields.find(
          (item) => item.table && item.table.key === tableKey
        );
        for (let i in currentTable.table.modalFields) {
          if (currentTable.table.modalFields[i].type === 'checkbox') {
            this.modalForm = {
              ...this.modalForm,
              [currentTable.table.modalFields[i].key]: false,
            };
          } else {
            this.modalForm = {
              ...this.modalForm,
              [currentTable.table.modalFields[i].key]: '',
            };
          }
        }
      }
      delete this.modalForm.id;
      this.showModalForm = true;
    },

    onSaveModalForm(tableKey) {
      this.showModalForm = false;
      for (let i in this.modalForm) {
        if (this.modalForm[i] === '') {
          delete this.modalForm[i];
        }
      }
      if (this.editItemIndex !== null) {
        this.values[tableKey].splice(this.editItemIndex, 1, {
          ...this.modalForm,
        });
      } else {
        this.values[tableKey].push({ ...this.modalForm });
      }
      this.editItemIndex = null;
      this.clearModalForm();
    },

    onCancelSaveModalForm() {
      this.editItemIndex = null;
      this.showModalForm = false;
      this.clearModalForm();
    },

    clearModalForm() {
      this.modalForm = {};
    },

    // deleteListItem(tableKey, index) {
    //   this.values[tableKey].splice(index, 1);
    // },

    openDeleteModal(tableKey, index) {
      this.$root
        .$confirmModal({
          message: 'Удалить запись?',
          leftButtonText: 'Да',
          rightButtonText: 'Нет',
        })
        .then((response) => {
          if (response) {
            this.values[tableKey].splice(index, 1);
          }
        });
    },

    onSave() {
      this.$emit('save');
    },

    onCancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss">
.editable-item__breadcrumbs {
  margin-bottom: 15px;
}

.editable-item__header {
  margin-bottom: 30px;
}

.editable-item__form-group {
  margin-bottom: 30px;

  border-bottom: 1px solid var(--light-gray);
  &:last-of-type {
    border: none;
  }
}

.editable-item__form-group-title {
  margin-bottom: 20px;
}

.editable-item__dependent-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;

  font-size: 20px;
}

.editable-item__dependent-item {
  margin-right: 15px;

  &:last-of-type {
    margin-right: 0;
  }
}

.editable-item__dependent-link {
  color: var(--blue);

  border-bottom: 1px dashed var(--blue);
}

.editable-item__form-button-group {
  display: flex;
}

.editable-list__top-panel-button {
  display: flex;
  align-items: center;

  font-weight: 500;
  line-height: 1;
  color: var(--gray);

  margin: 0;
  border: none;
  background: transparent;
}

.editable-list__top-panel-button--delete {
  &:hover {
    color: var(--red);
  }
}

.editable-item__form-button {
  width: 180px;
  margin-right: 15px;

  &:last-of-type {
    margin-right: 0;
  }
}

.editable-list__datatable-buttons {
  // width: 80px;

  text-align: center;
}

.editable-list__top-panel-button--create,
.editable-list__datatable-button {
  &:hover {
    color: var(--blue);
  }
}

.editable-list__datatable-button {
  margin-right: 5px;

  font-size: 0;
  color: var(--gray);

  margin: 0;
  border: none;
  background: transparent;

  &:last-of-type {
    margin-right: 0;
  }

  &--edit {
    height: 36px;
    width: 36px;
    border-radius: 5px;
    color: var(--white);
    background-color: var(--green);
    transition: background-color 0.2s;

    &:hover {
      color: var(--white);
      background-color: var(--dark-blue);
    }
  }

  &--view {
    height: 36px;
    width: 36px;
    border-radius: 5px;
    color: var(--white);
    background-color: var(--blue);
    transition: background-color 0.2s;

    &:hover {
      color: var(--white);
      background-color: var(--dark-blue);
    }
  }

  &--delete {
    height: 36px;
    width: 36px;
    border-radius: 5px;
    color: var(--white);
    background-color: var(--red);
    transition: background-color 0.2s;

    &:hover {
      color: var(--white);
      background-color: var(--dark-blue);
    }
  }
}
</style>
