<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    :disabledSaveButton="isEqual"
    @save="onSave('IgnoredCustomers')"
    @cancel="onCancel('IgnoredCustomers')"
  />
</template>

<script>
// mixins
import editableListItemEdit from '@/mixins/editableListItemEdit.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'IgnoredCustomersEdit',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
			isLoadingPage: false,
      controllerName: 'IgnoredCustomers',
      pageHeader: 'Редактирование клиента',
      form: {},
    };
  },

  computed: {
    ...mapState({
      item: (state) => state.editableList.item,
      customers: (state) => state.common.customers.data,
    }),

    breadcrumbs() {
      return [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Клиенты, по которым не контролировать долги',
          route: { name: 'IgnoredCustomers' },
        },
        {
          text: this.pageHeader,
        },
      ];
    },

    fields() {
      return [
        {
          form: [
            {
              type: 'select',
              key: 'customerId',
              label: 'Имя клиента',
              list: this.customers,
            },
          ],
        },
      ];
    },
  },

  created() {
    this.isLoadingPage = true;

    const item = this.getItem({ name: this.controllerName, id: this.id }).then(
      () => {
        this.form = this.lodash.cloneDeep(this.item.data);
      }
    );
    const customers = this.getCommonList({ name: 'Customers' });

    Promise.all([item, customers]).finally(() => {
      this.isLoadingPage = false;
    });
  },

  methods: {
    ...mapActions({
      getItem: 'editableList/getItem',
      updateItem: 'editableList/updateItem',
      getCommonList: 'common/getCommonList',
    }),
  },
};
</script>
